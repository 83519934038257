/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

@import "assets/css/grid.css";


app-root {
    display: block;
    height: 100%;
}

mat-sidenav-container {
    height: calc(100% - 64px);
}

.mat-column-formula {
    min-width: 300px;
}

.mdc-card__actions {
    padding: 0 16px 16px !important;
}

.mat-mdc-cell {
    overflow-wrap: break-word;
    word-break: break-all;
}

.mdc-button .mdc-button__label {
    position: relative;
    white-space: nowrap;
}

.mdc-dialog__actions {
    padding: 12px 24px 24px !important;
}

.mat-mdc-dialog-content {
    max-height: calc(100vh - 140px) !important;
}

.mdc-dialog__content:not(:last-child) {
    padding-bottom: 0 !important;
}

.mat-mdc-dialog-actions {
    justify-content: end !important;
}

.bmp-mainnav {
    .mat-mdc-list-item-unscoped-content {
        display: flex;
        align-items: center;
        gap: 12px;
    }
}

.mdc-list-item.mdc-list-item--with-one-line.is-active {
    background-color: #e1e6ff !important;
}

.bmp-subheader {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    gap: 24px;
    margin-bottom: 16px;
}

.bmp-subheader h3 {
    margin: 0;
}

.w-100 {
    width: 100% !important;
}

.mr-auto {
    margin-right: auto;
}

.bmp-form-container {
    display: flex;
    flex-direction: column;
}

.mb-formitem {
    margin-bottom: 22px !important;
}

.bmp-formula-creator {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    background-color: #D6D8E0;
    padding: 12px;
}

.bmp-formula-creator-left,
.bmp-formula-creator-right {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.bmp-formula-creator-left {
    flex: 1;
    min-width: 0;
}

.bmp-formula-creator-right {
    flex: 0 0 270px;
    min-width: 0;
}

.bmp-formula-creator-row {
    display: flex;
    gap: 4px;
}

.bmp-formula-creator-row button {
    flex: 1 1 auto;
    min-width: 0;
    background: #fff;
}

.bmp-formula-creator-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
}

.bmp-formula-creator-buttons button {
    flex: 1 1 auto;
    min-width: 0;
    background: #fff;
}

.bmp-modal-lg {
    max-width: 860px !important;
}

code {
    white-space: normal;
    word-wrap: break-word;
    background: rgba(63, 81, 181, .09);
    padding: 8px 20px;
    border-radius: 4px;
    border-left: 4px solid #3f51b5;
}

.bmp-container-md {
    max-width: 1200px;
    margin: 0 auto 0;
    padding: 20px;
}

.bmp-table-icons,
.mat-column-actions {
    justify-content: flex-end;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.bmp-table-icons>*,
.mat-column-actions>* {
    margin-left: 8px;
    flex: 0 0 auto;
}

.add-mat-table tbody td {
    padding-top: 20px;
}
.bmp-btn-wrap {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
}

.bmp-table-scroll {
    overflow: auto;
}